import { MeterEnergyType } from '@/models';
import { ConsumptionAddress } from '@/models/api'

export interface IdentificationState {
  ean: string | null
  meterLastFourNumbers: string | null
  energyType: MeterEnergyType | null
  address: ConsumptionAddress | null
}

export function GetInitialState(): IdentificationState {
  return {
    ean: null,
    meterLastFourNumbers: null,
    energyType: null,
    address: null
  }
}
