import {
  FileInfo,
} from '@/models';

export interface AttachmentsState {
  attachDocuments: boolean;
  attachments: FileInfo[];
}

export const InitialState: AttachmentsState = {
  attachDocuments: false,
  attachments: [],
};
