import Vue from 'vue';
import { AppInsights } from 'applicationinsights-js';
import VueRouter from 'vue-router';

declare module 'vue/types/vue' {

  interface Vue {
    $appInsights: typeof AppInsights;
  }

  interface VueConstructor {
    appInsights: typeof AppInsights;
  }
}

/**
 * Track route changes as page views with AppInsights
 * @param options
 */
function setupPageTracking(options: AppInsightsOption, vueCtor: typeof Vue) {

  const router = options.router;

  const baseName = options.baseName || '(Vue App)';

  if (router) {
    router.beforeEach((route, from, next) => {
      const name = baseName + ' / ' + route.name;
      vueCtor.appInsights.startTrackPage(name);
      next();
    });

    router.afterEach((route) => {
      const name = baseName + ' / ' + route.name;
      const url = location.protocol + '//' + location.host + route.fullPath;
      vueCtor.appInsights.stopTrackPage(name, url);
    });
  }
}

/**
 * Install function passed to Vue.use() show documentation on vue.js website.
 *
 * @param vueCtor
 * @param options
 */
export function VueAppInsights(vueCtor: typeof Vue, options: AppInsightsOption) {
  const id = options.id;

  if (options.appInsights) {
    vueCtor.appInsights = options.appInsights;
  } else {
    vueCtor.appInsights = AppInsights;
    if (vueCtor.appInsights.downloadAndSetup) {
      vueCtor.appInsights.downloadAndSetup({ instrumentationKey: id });
    }
  }

  const router = options.router;

  // Watch route event if router option is defined.
  if (router) {

    if (options.trackInitialPageView !== false) {
      setupPageTracking(options, vueCtor);
    } else {
      router.onReady(() => {
        return setupPageTracking(options, vueCtor);
      });
    }
  }

  vueCtor.prototype.$appInsights = vueCtor.appInsights;
}

export interface AppInsightsOption {
  id: string;
  baseName?: string;
  trackInitialPageView?: boolean;
  appInsights?: typeof AppInsights;
  router?: VueRouter;
  property?: { [key: string]: string };
  IConfig?: {
    disableTelemetry: boolean,
    disableAjaxTracking: boolean,
  };
}
