import { ActionTree } from 'vuex';
import { EnergyState } from './types';
import { RootState } from '@/store/types';
import { CategorySelectionStepModel } from '@/models';
import { complaintService } from '@/services';

export const actions: ActionTree<EnergyState, RootState> = {
  validate({ commit, state }, category: CategorySelectionStepModel): boolean {
    if (complaintService.isInList(category, complaintService.energyRequiredList) &&
      (state.energySelectionStepInfo?.energySelection === null ||
        (state.energySelectionStepInfo?.energySelection.electricity === false &&
          state.energySelectionStepInfo.energySelection.gas === false))) {
      return false;
    }
    return true
  },
};
