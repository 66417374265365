import { MutationTree } from 'vuex';
import { EnergyState } from './types';
import { EnergySelection } from '@/models'

export const mutations: MutationTree<EnergyState> = {
  STORE(state, data: EnergySelection | null) {
    state.energySelection = data
  },
  CLEAR(state) {
    state.energySelection = null
  }
}
