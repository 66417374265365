import { MutationTree } from 'vuex';
import { CategorySelectionState } from './types';
import { CategorySelectionStepModel } from '@/models';

export const mutations: MutationTree<CategorySelectionState> = {
  STORE(state, data: CategorySelectionStepModel) {
    state.category = data;
  },
  CLEAR(state) {
    state.category = null;
  },
};
