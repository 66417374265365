import { Module } from 'vuex'
import { getters } from './getters'
import { mutations } from './mutations'
import { AddressState, GetInitialState } from './types'
import { RootState } from '@/store/types'

export const address: Module<AddressState, RootState> = {
  namespaced: true,
  state: GetInitialState(),
  getters,
  mutations
}
