import { CategorySelectionStepModel } from '@/models';

export interface ComplaintCategoryList {
  parentCategory: string[] | null,
  category: string[] | null
}

const indemnityRequestList = {
  parentCategory: [
    'indemnityRequest'
  ],
  category: null
};

const eanRequiredList = {
  parentCategory: [
    'myInstallation',
    'networkProblems'
  ],
  category: [
    'notScheduledSupplyCutoff',
    'notScheduledElectricityCutoff',
    'irregularElecticityCutoff',
    'electricityGazSupplierSwitchDelay',
    'electricityGazInvoiceError',
    'solarRequestProcessingDelay',
    'gaz',
    'electricity'
  ]
};

const numberNotRequiredList = {
  parentCategory: [
    'oresWorkInitiative',
    'oresEmployeeInteraction',
    'indemnityRequest'
  ],
  category: [
    'streetlight',
    'rechargingTerminal'
  ]
};

const streetNotRequiredList = {
  parentCategory: [
    'oresEmployeeInteraction'
  ],
  category: null
};

const energyRequiredList = {
  parentCategory: [
    'myInstallation'
  ],
  category: [
    'meterReadingRequest',
    'worksCutOffMeterReplacement',
    'invoice',
    'prime',
    'viabilisationFee',
    'distributionTariff',
    'pavementRenovation',
    'publicHighwayWorks',
    'irregularElecticityCutoff',
    'damageDueToElectricityIncident',
    'connectionWorkDelay',
    'electricityGazSupplierSwitchDelay',
    'electricityGazInvoiceError'
  ]
};

const streetLightRefOptionalList = {
  parentCategory: null,
  category: [
    'streetlight'
  ]
};

const cabinNumberOptionalList = {
  parentCategory: null,
  category: [
    'electricCabinMaintenance'
  ]
};

const rechargingTerminalOptionalList = {
  parentCategory: null,
  category: [
    'rechargingTerminal'
  ]
};

const damageTypeRequiredList = {
  parentCategory: null,
  category: [
    'damageDueToElectricityIncident'
  ]
};

const addressLabelList = {
  parentCategory: [
    'rgpdDataManagement'
  ],
  category: null
};

const consumptionAddressLabelList = {
  parentCategory: null,
  category: [
    'worksCutOffMeterReplacement',
    'meterReadingRequest',
    'distributionTariff'
  ]
};

const complaintAddressLabelList = {
  parentCategory: [
    'oresEmployeeInteraction'
  ],
  category: [
    'invoice',
    'prime',
    'viabilisationFee',
    'electricCabinMaintenance',
    'pavementRenovation',
    'publicHighwayWorks',
    'streetlight',
    'rechargingTerminal',
    'connectionWorkDelay',
    'damageDueToElectricityIncident'
  ]
};

const oresAddresOnlyList = {
  parentCategory: [
    'oresLetter',
    'oresWorkInitiative'
  ],
  category: [
    'streetlight',
    'rechargingTerminal',
    'connectionWorkDelay'
  ]
};

const indemnitySendToLegal = {
  parentCategory: [
    'oresWorkInitiative',
    'failure'
  ],
  category: null
}

function isInList(category: CategorySelectionStepModel | null, list: ComplaintCategoryList): boolean {
  return category !== null &&
    ((
      category.parentCategory !== null &&
      list.parentCategory !== null &&
      list.parentCategory.includes(category.parentCategory?.key)
    ) ||
      (
        category.category !== null &&
        list.category != null &&
        list.category.includes(category.category?.key)
      ))
}

export const complaintService = {
  indemnityRequestList,
  eanRequiredList,
  numberNotRequiredList,
  streetNotRequiredList,
  energyRequiredList,
  streetLightRefOptionalList,
  cabinNumberOptionalList,
  rechargingTerminalOptionalList,
  damageTypeRequiredList,
  addressLabelList,
  complaintAddressLabelList,
  consumptionAddressLabelList,
  oresAddresOnlyList,
  indemnitySendToLegal,
  isInList
}
