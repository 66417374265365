import { Module } from 'vuex'
import { GetInitialState, EanRequestState } from './types'
import { RootState } from '@/store/types'
import { mutations } from './mutations'
import { getters } from './getters'
import { address } from './address'
import { contact } from './contact'
import { actions } from './actions'

export const eanRequest: Module<EanRequestState, RootState> = {
  namespaced: true,
  state: GetInitialState(),
  mutations,
  actions,
  getters,
  modules: {
    address,
    contact
  }
}
