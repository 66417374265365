import { EnergyProvider, RegularisationEanInformation } from '@/models'
import { MutationTree } from 'vuex'
import { GetInitialState, RegularisationMetersState } from './types'

export const mutations: MutationTree<RegularisationMetersState> = {
  STORE_PROVIDERS(state, data: EnergyProvider[]) {
    state.energyProviders = data
  },
  STORE_MOVINGDATE(state, data: Date) {
    state.movingDate = data
  },
  STORE_EANINFOS(state, data: RegularisationEanInformation) {
    const idx = state.eanInfos.findIndex((o) => o.ean === data.ean)
    state.eanInfos[idx] = Object.assign({}, data)
    state.eanInfos = state.eanInfos.slice(0)
  },
  STORE_INFOCACHE(state, data: RegularisationEanInformation[]) {
    state.eanInfos = data.slice(0)
    state.eanInfosCache = data.slice(0)
  },
  CLEAR_PROVIDERS(state) {
    state.energyProviders = null
  },
  CLEAR_MOVINGDATE(state) {
    state.movingDate = null
  },
  CLEAR_EANINFOS(state, ean: string) {
    const idx = state.eanInfos.findIndex((o) => o.ean === ean)
    state.eanInfos[idx] = Object.assign({}, state.eanInfosCache[idx])
    state.eanInfos = state.eanInfos.slice(0)
  },
  CLEAR_USERDATA(state) {
    state = Object.assign(state, GetInitialState(), { energyProviders: state.energyProviders })
  },
  CLEAR(state) {
    state = Object.assign(state, GetInitialState())
  }
}
