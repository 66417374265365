import { MutationTree } from 'vuex'
import { OwnerState, GetInitialState } from './types'
import { OwnerInfo } from '@/models';

export const mutations: MutationTree<OwnerState> = {
  STORE_ISSAME(state, data: boolean) {
    state.isSameAsClientInfo = data
  },
  STORE_OWNERKNOWN(state, data: boolean) {
    state.isOwnerInfoKnown = data
  },
  STORE_OWNERINFOS(state, data: OwnerInfo) {
    state.ownerInfos = data
  },
  CLEAR_OWNERINFOS(state) {
    state = Object.assign(state, GetInitialState(), {
      isSameAsClientInfo: state.isSameAsClientInfo,
      isOwnerInfoKnown: state.isOwnerInfoKnown
    })
  },
  CLEAR(state) {
    state = Object.assign(state, GetInitialState())
  }
}
