import { MeterEnergyType, RegisterType } from '@/models'

export interface GetEansByAddressResponse {
  items: EanInformation[]
}

export interface GridAccessPoint {
  eanCode: string | null;
  consumptionAddress: ConsumptionAddress;
  energyType: EnergyType | null;
  meterSerialNumbers: string[] | null;

}

export interface ConsumptionAddress {
  subCityId: number | null;
  cityName: string | null;
  zipCode: string | null;
  streetSubcityId: number | null;
  streetName: string | null;
  houseNumber: string | null;
  complement: string | null;
  complementBox: string | null;
  floorNumber: string | null;
}

export enum EnergyType {
  Electricity = 'electricity',
  Gas = 'gas'
}

export enum RegisterDirection {
  Consumption = 'consumption',
  Production = 'production'
}

export enum TariffType {
  Simple = 'simple',
  Dual = 'dual',
  NightExclusive = 'nightExclusive'
}

export interface RegisterInformation {
  registerType: RegisterType
  direction: RegisterDirection | null
  isDay: boolean
  isNight: boolean
  value: string | null
}

export interface MeterInformation {
  meterId: string
  isActive: boolean
  isBudgetMeter: boolean
  isCustomBudgetMeter: number
  tariffType: TariffType | null
  isSimple: boolean
  isDayAndNight: boolean
  isNightExclusive: boolean
  registerInfos: RegisterInformation[]
}

export interface EanInformation {
  ean: string
  energyType: MeterEnergyType
  meterInfos: MeterInformation[]
}

/*
 * CONSENTS
 */

export type ConsentsDict = Record<keyof typeof ProcessingType, Record<keyof typeof PersonalDataType, boolean>>

export interface ConsentsForAddress {
  generatedId: string,
  consents: ConsentsDict,
}

export enum ProcessingType {
  Failure = 1,
  PlannedOutage = 2,
  DigitalLetter = 3,
}

export enum PersonalDataType {
  Email,
  Sms,
}

/*
 * PRC Request + Complaint
 */

export interface GetGridAccessPointInfoResponse {
  gridAccessPointInfo: GridAccessPoint | null
  token: string | null;
}
