import { MutationTree } from 'vuex'
import { ContactInputState, ContactState, GetInitialState } from './types'

export const mutations: MutationTree<ContactState> = {
  STORE_INPUT(state, data: ContactInputState) {
    state.contactInput = data
  },
  STORE_ISEANFOUND(state, data: boolean) {
    state.isEanFound = data
  },
  SET_RETRY(state) {
    state.isRetry = true
  },
  CLEAR(state) {
    Object.assign(state, GetInitialState())
  }
}
