import address from './address';
import yesNoMaybeSelector from './yes-no-maybe-selector-required';
import complaintNumberFile from './complaint-number-file';
import nationalId from './national-id';

export default {
  address,
  complaintNumberFile,
  yesNoMaybeSelector,
  nationalId,
};
