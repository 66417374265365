import { HouseNumberAsset, StreetSubCity, SubCity } from '@/models'

export interface AddressState {
  subCity: SubCity | null
  streetSubCity: StreetSubCity | null
  houseNumberAsset: HouseNumberAsset | null
  meterSerialNumberLastFourDigits: string | null
}

export function GetInitialState(): AddressState {
  return {
    subCity: null,
    streetSubCity: null,
    houseNumberAsset: null,
    meterSerialNumberLastFourDigits: null
  }
}
