import { SubCity } from '@/models';
import { MutationTree } from 'vuex';
import { OldAddressState } from './types';
import { GetElectricityAndGasGridAccessPointsByEanResult, GridAccessPoint } from '@/models/protected-customer-request'

export const mutations: MutationTree<OldAddressState> = {
  STORE_IDENTIFICATION_INFO(
    state,
    data: {
      subCity: SubCity | null,
      eanCodeElectricity: string | null,
      eanCodeGas: string | null
    }) {
    state.subCity = data.subCity
    state.eanCodeElectricity = data.eanCodeElectricity
    state.eanCodeGas = data.eanCodeGas
  },
  STORE_GRID_ACCESS_POINT_INFO(state, data: GetElectricityAndGasGridAccessPointsByEanResult | null) {
    if (data) {
      state.gridAccessPointElectricity = data.gridAccessPointElectricity
      state.gridAccessPointGas = data.gridAccessPointGas
      state.isSameAddress = data.isSameAddress
    } else {
      state.gridAccessPointElectricity = null
      state.gridAccessPointGas = null
      state.isSameAddress = null
    }
  },
  STORE_GRID_ACCESS_POINT_ELECTRICITY(state, data: GridAccessPoint | null) {
    state.gridAccessPointElectricity = data
  },
  STORE_GRID_ACCESS_POINT_GAS(state, data: GridAccessPoint | null) {
    state.gridAccessPointGas = data
  },
  CLEAR(state) {
    state.subCity = null
    state.eanCodeElectricity = null
    state.eanCodeGas = null
    state.gridAccessPointElectricity = null
    state.gridAccessPointGas = null
    state.isSameAddress = null
  }
}
