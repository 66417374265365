export class StepDefinition {
  public name!: string;
  public step!: number;
  public children?: StepDefinition[];

  constructor(name: string, step: number, children?: StepDefinition[]) {
    this.name = name;
    this.step = step;
    this.children = children;
  }

  public getStatus(currentStep: number): string {
    if (currentStep <= this.step) {
      return '';
    }

    if (this.children) {
      for (const child of this.children) {
        if (child.step >= currentStep) {
          return 'active';
        }
      }
    }

    return 'done';
  }
}
