import Vue from 'vue';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import { ValidationObserver } from 'vee-validate';

declare module 'vue/types/vue' {

  interface Vue {
    $validatorObs: InstanceType<typeof ValidationObserver>;
  }

  interface VueConstructor {
    $validatorObs: InstanceType<typeof ValidationObserver>;
  }
}

export function ValidatorObserver(vueCtor: typeof Vue, store: Store<RootState>): void {
  // tslint:disable-next-line: max-line-length
  vueCtor.prototype.__defineGetter__('$validatorObs', function(this: Vue) { return this.$refs.validator as InstanceType<typeof ValidationObserver>; });
}
