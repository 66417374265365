import { MutationTree } from 'vuex'
import { PrcRequestState, GetInitialState } from './types'

export const mutations: MutationTree<PrcRequestState> = {
  STORE_BEARER(state, data: string) {
    state.bearer = data
  },
  STORE_WEBREF(state, data: string) {
    state.requestId = data
  },
  CLEAR_BEARER(state) {
    state.bearer = null
  },
  CLEAR(state) {
    state = Object.assign(state , GetInitialState())
  }
}
