import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api'
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './i18n';
import validation from './validation';
import { initClickHandlers } from '@ores/css-library';
import OresPlugin from '@ores/vue-library';
import { InstallApp } from './plugins';

Vue.prototype.$buildComponent = false;
Vue.config.productionTip = false;

// configure translations
validation.configure('fr');

initClickHandlers();

Vue.use(InstallApp, { store });
Vue.use(OresPlugin, { i18n });
Vue.use(VueCompositionAPI)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
