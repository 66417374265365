import { MutationTree } from 'vuex'
import { AddressState, GetInitialState } from './types'
import { EanRequestAddress } from '@/models'

export const mutations: MutationTree<AddressState> = {
  STORE(state, data: EanRequestAddress) {
    if (!data.subCity) {
      throw TypeError('subCity is null')
    }
    if (!data.streetSubCity) {
      throw TypeError('streetSubCity is null')
    }
    if (!data.houseNumberAsset) {
      throw TypeError('houseNumberAsset is null')
    }
    if (!data.meterSerialNumberLastFourDigits) {
      throw TypeError('meterSerialNumberLastFourDigits is null')
    }
    state.subCity = data.subCity
    state.streetSubCity = data.streetSubCity
    state.houseNumberAsset = data.houseNumberAsset
    state.meterSerialNumberLastFourDigits = data.meterSerialNumberLastFourDigits
  },
  CLEAR(state) {
    Object.assign(state, GetInitialState())
  }
}
