import Vue from 'vue';
import { helper } from '@/services';

declare module 'vue/types/vue' {

  interface Vue {
    $imageProvider: (picture: string) => string;
  }

  interface VueConstructor {
    $imageProvider: (picture: string) => string;
  }
}

export function ImageProvider(vueCtor: typeof Vue): void {
  vueCtor.prototype.$imageProvider = helper.getImg;
}
