import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { PrcRequestState } from './types'

export const actions: ActionTree<PrcRequestState, RootState> = {
  CLEAR_STEPS({commit}, currentStep: number): void {
    const stepToInvalidateMap = new Map<number, number[]>([
      [1, [6, 7, 8]],
      [2, [4]],
      [3, []],
      [4, []],
      [5, []],
      [6, [7]],
      [7, []],
      [8, []],
      [9, []],
      [10, []],
      [11, []],
    ])

    const stepTypeMap = new Map<number, string[]>([
      [1, ['customer/CLEAR']],
      [2, ['energy/CLEAR']],
      [3, ['prosumer/CLEAR']],
      [4, ['CLEAR_BEARER', 'identification/CLEAR']],
      [5, ['meterReading/CLEAR']],
      [6, ['oldAddressEnergy/CLEAR']],
      [7, ['oldAddress/CLEAR']],
      [8, ['oldAddressMeterReading/CLEAR']],
      [9, ['attestation/CLEAR']],
      [10, ['contactInfo/CLEAR']],
      [11, ['owner/CLEAR']]
    ])

    stepToInvalidateMap.get(currentStep)?.forEach(
      (step) => stepTypeMap.get(step)?.forEach(
        (type) => commit(type)))
  },
  RESET({commit}): void {
    commit('customer/CLEAR')
    commit('energy/CLEAR')
    commit('prosumer/CLEAR')
    commit('identification/CLEAR')
    commit('meterReading/CLEAR')
    commit('oldAddressEnergy/CLEAR')
    commit('oldAddress/CLEAR')
    commit('oldAddressMeterReading/CLEAR')
    commit('attestation/CLEAR')
    commit('contactInfo/CLEAR')
    commit('owner/CLEAR')
    commit('CLEAR')
  }
}
