import { MutationTree } from 'vuex';
import { NewOrTrackingState } from './types';

export const mutations: MutationTree<NewOrTrackingState> = {
  STORE(state, data: string) {
    state.newOrTracking = data;
  },
  CLEAR(state) {
    state.newOrTracking = null;
  },
};
