import { MutationTree } from 'vuex';
import { ProsumerState } from './types';

export const mutations: MutationTree<ProsumerState> = {
  STORE(state, data: number | null) {
    state.isProsumer = data
  },
  CLEAR(state) {
    state.isProsumer = null
  }
}
