import { MutationTree } from 'vuex';
import { ComplaintInfoState } from './types';
import { ComplaintInfo } from '@/models';

export const mutations: MutationTree<ComplaintInfoState> = {
  STORE(state, data: ComplaintInfo) {
    state.complaintInfo = data;
  },
  CLEAR(state) {
    state.complaintInfo = null;
  },
};
