import Vue from 'vue';
import axios, { AxiosInstance, AxiosError } from 'axios';
import { setupCache, setup } from 'axios-cache-adapter';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import { addInterceptors } from './http-interceptors';

declare module 'vue/types/vue' {

  interface Vue {
    $addressRepository: AxiosInstance;
  }

  interface VueConstructor {
    $addressRepository: AxiosInstance;
  }
}

export function AddressRepositoryAxios(vueCtor: typeof Vue, store: Store<RootState>): void {
  // No cache by default
  const addressRepository = setup({
    baseURL: process.env.VUE_APP_ADDRESS_API_URL,
    cache: {
      maxAge: 60 * 60 * 1000,
      ignoreCache: true,
      exclude: {
        query: false,
      },
    },
  });

  addInterceptors(addressRepository, store);

  vueCtor.prototype.$addressRepository = addressRepository;
}
