import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { ApiErrorsState } from './types';
import { AxiosResponse } from 'axios';
import { i18n } from '@/i18n';

export const actions: ActionTree<ApiErrorsState, RootState> = {
  manage({ commit }, error: AxiosResponse<any> | undefined) {
    if (!error) {
      commit('SET_API_ERRORS', { danger: [i18n.t('error.tryLater')] });
      return;
    }

    switch (error.status) {
      case 400:
        commit('SET_API_ERRORS', { danger: [i18n.t('error.badRequestMessage')] });
        break
      case 401:
        commit('SET_API_ERRORS', {
          danger: [i18n.t('error.identificationExpired'),
          i18n.t('error.refreshForm')]
        })
        break
      case 403:
      case 500:
      case 503:
        commit('SET_API_ERRORS', { danger: [i18n.t('error.tryLater')] });
        break
    }
  },
  setApiError({ commit }) {
    commit('SET_API_ERRORS', { danger: [i18n.t('error.tryLater')] });
  },
  clear({ commit }) {
    commit('CLEAR_API_ERRORS');
  },
};
