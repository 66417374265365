import { MutationTree } from 'vuex';
import { AttachmentsState } from './types';
import { FileInfo } from '@/models';

export const mutations: MutationTree<AttachmentsState> = {
  STORE_ATTACHMENTS(state, data: FileInfo[]) {
    state.attachDocuments = !!data.length;
    state.attachments = data;
  },
  CLEAR(state) {
    state.attachDocuments = false;
    state.attachments = [];
  },
};
