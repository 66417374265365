import { MutationTree } from 'vuex';
import { RootState } from './types';

export const mutations: MutationTree<RootState> = {
  SET_LANGUAGE(state: RootState, lang: string) {
    state.lang = lang;
  },
  SET_ISBACK(state: RootState, isBack: boolean) {
    state.isBack = isBack;
  },
};
