import { FileInfo } from '@/models'

export interface AttestationState {
  attachDocuments: boolean;
  attachments: FileInfo[];
}

export const InitialState: AttestationState = {
  attachDocuments: false,
  attachments: [],
}
