import { ActionTree } from 'vuex';
import { ComplaintInfoState } from './types';
import { RootState } from '@/store/types';
import { CategorySelectionStepModel } from '@/models';
import { complaintService } from '@/services';

export const actions: ActionTree<ComplaintInfoState, RootState> = {
  validate({ commit, state }, category: CategorySelectionStepModel): boolean {
    if (complaintService.isInList(category, complaintService.damageTypeRequiredList) &&
      state.complaintInfo?.damageType === null) {
      return false
    }

    if (complaintService.isInList(category, complaintService.indemnityRequestList) &&
      (state.complaintInfo?.sinisterDate === null || state.complaintInfo?.bankAccountIban === null)) {
      return false
    }

    const isSinisterDateMustBeDeleted = !(
      (complaintService.isInList(category, complaintService.indemnitySendToLegal) &&
        state.complaintInfo?.isIndemnitySendToLegal &&
        state.complaintInfo.sinisterDate !== null) ||
      complaintService.isInList(category, complaintService.indemnityRequestList)
    )

    if (isSinisterDateMustBeDeleted) {
      return false
    }

    return true
  },
};
