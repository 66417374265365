import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { ComplaintState } from './types';

export const actions: ActionTree<ComplaintState, RootState> = {
  reset({ commit }) {
    commit('complaintType/CLEAR');
    commit('newOrTracking/CLEAR');
    commit('categorySelection/CLEAR');
    commit('address/CLEAR');
    commit('energy/CLEAR');
    commit('attachments/CLEAR');
    commit('complaintInfo/CLEAR');
    commit('contactInfo/CLEAR');
    commit('RESET');
  },
};
