import { IdentificationModel } from '@/models';
import { MutationTree } from 'vuex';
import { IdentificationState } from './types';

export const mutations: MutationTree<IdentificationState> = {
  STORE(state, data: IdentificationModel) {
    state.identification = data;
  },
  CLEAR(state) {
    state.identification = null
  }
}
