import { MutationTree } from 'vuex'
import { ContactState, GetInitialState } from './types'
import { RegularisationContactInfos } from '@/models';

export const mutations: MutationTree<ContactState> = {
  STORE_ISPRO(state, data: boolean | null) {
    state.isPro = data
    if (state.contactInfos !== null && state.contactInfos.contactBaseInfo !== null) {
      if (!data) {
        state.contactInfos.contactBaseInfo.contactType = 'individual'
      } else if
        (
        state.contactInfos.contactBaseInfo.contactType === null ||
        state.contactInfos.contactBaseInfo.contactType === 'individual'
        ) {
        state.contactInfos.contactBaseInfo.contactType = 'freelanceOrCompany'
      }
    }
  },
  STORE_CONTACTINFOS(state, data: RegularisationContactInfos) {
    if (!data.formerAddress?.subCity?.zipCode) {
      data.formerAddress = null
    }
    state.contactInfos = data
  },
  CLEAR_CONTACTINFOS(state) {
    state.contactInfos = Object.assign(state.contactInfos, GetInitialState().contactInfos)
  },
  CLEAR_ISPRO(state) {
    state.isPro = null
    if (state.contactInfos !== null && state.contactInfos.contactBaseInfo !== null) {
      state.contactInfos.contactBaseInfo.contactType = null
    }
  },
  CLEAR(state) {
    state = Object.assign(state, GetInitialState())
  }
}
