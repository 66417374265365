import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { InitialState, CategorySelectionState } from './types';
import { RootState } from '@/store/types';

const namespaced: boolean = true;

export const categorySelection: Module<CategorySelectionState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations,
};
