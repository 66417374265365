class GoogleService {
  private static scriptIncluded = false;

  public includeMapScript(currentElement: Element) {
    if (GoogleService.scriptIncluded) {
      return;
    }

    GoogleService.scriptIncluded = true;

    const gmapsScript = document.createElement('script');
    gmapsScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?client=gme-oresscrl&libraries=geometry');

    let root: Node = currentElement;
    while (root.parentNode != null) {
      root = root.parentNode;
    }

    if (root.nodeType !== Node.DOCUMENT_FRAGMENT_NODE) {
      root = document.head;
    }

    root.appendChild(gmapsScript);
  }
}

export const googleService = new GoogleService();
