import { GetterTree } from 'vuex'
import { RegularisationState } from './types';
import { RootState } from '@/store/types'

export const getters: GetterTree<RegularisationState, RootState> = {
  httpHeaders(state: RegularisationState): any {
    const headers: any = {
      WebRef: state.requestId
    }

    if (!!state.bearer) {
      headers.Authorization = `Bearer ${state.bearer}`
    }

    return headers
  },
}
