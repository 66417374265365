import { ContactBaseInfo, ContactAddress, ContactInfo, ContactPerson, SubCity, StreetSubCity } from '@/models';
import { ConsumptionAddress } from '@/models/common'

function getFormattedConsumptionAddress(address: ConsumptionAddress | null): string {
    if (address) {
      let finalAddress = address.streetName + ' ' + address.houseNumber
      if (address.complement) {
        finalAddress += ' ' + address.complement
      }
      if (address.complementBox) {
        finalAddress += ' ' + address.complementBox
      }
      if (address.floorNumber) {
        finalAddress += 'étage ' + address.floorNumber
      }
      finalAddress += ' - ' + address.zipCode + ' ' + address.cityName
      return finalAddress
    }

    return ''
}

function getFormattedAddress(address: ContactAddress | null): string {
  if (!address) {
    return ''
  }
  const nmbr = address.houseNumber
  const box = address.houseBox || ''
  const country = !!address.country ? `${address.country}` : ''
  return `${address.streetSubCity?.name} ${nmbr}${box} - ${address.subCity?.zipCode} ${address.subCity?.cityName} ${country}`
}

function createContactBase(): ContactBaseInfo {
  return {
    contactType: null,
    civility: null,
    firstName: null,
    lastName: null,
    email: null,
    job: null,
    phone: null,
    mobile: null,
    birthdate: null,
    vatNumber: null,
    nationalId: null,
    bankAccountBic: null,
    bankAccountIban: null,
    customerRef: null,
    companyName: null,
    companyType: null
  };
}

function createContactAddress(): ContactAddress {
  return {
    subCity: null,
    streetSubCity: null,
    houseNumber: null,
    houseBox: null,
    country: null
  };
}

function createContactInfo(): ContactInfo {
  return {
    contactBase: createContactBase(),
    useOtherAddress: false,
    otherAddress: null,
    addOtherContact: false,
    otherContact: null,
  };
}

function createContactPerson(): ContactPerson {
  return {
    civility: null,
    firstName: null,
    lastName: null,
    job: null,
    phone: null,
    mobile: null,
    birthdate: null,
    email: null,
    address: {
      subCity: null,
      streetSubCity: null,
      houseNumber: null,
      houseBox: null,
      country: null
    },
    description: null
  }
}

function createSubCity(): SubCity {
  return {
    georesSubcityId: -1,
    zipCode: '-1',
    cityName: '',
    municipality: {
      georesMunicipalityId: -1,
      name: '',
    },
    isFictive: true,
    connectMyHomeActivated: false,
    connectMyHomeInformation: null,
    connectMyHomeActivationDate: null,
    gridOperators: null,
    gridManagers: {
      electricityManager: null,
      gasManager: null,
    },
    operationalOffices: {
      electricityOffice: null,
      gasOffice: null,
    },
  }
}

function createStreetSubCity(): StreetSubCity {
  return {
    georesStreetId: -1,
    georesStreetSubcityId: -1,
    name: '',
  }
}

function getContactEmail(contactInfo: ContactInfo): string {
  return contactInfo.contactBase!.email!;
}

function isNationalIdValid(id: string): boolean {
  if (id?.length !== 15) {
    return false
  }
  const year = parseInt(id.substring(0, 2), 10)
  const baseYear = parseInt(((new Date()).getFullYear() - 17).toString().substring(2), 10)
  const is2000 = year < baseYear
  const prefix = is2000 ? '2' : ''

  const base = id.replaceAll('.', '').replace('-', '')
  const tmp = parseInt(prefix + base.substring(0, 9), 10)
  const crc = parseInt(base.substring(9), 10)
  const computedCRC = 97 - (tmp % 97)

  return crc === computedCRC
}

export const contactService = {
  createContactBase,
  createContactAddress,
  createContactInfo,
  createContactPerson,
  getContactEmail,
  createSubCity,
  createStreetSubCity,
  getFormattedAddress,
  getFormattedConsumptionAddress,
  isNationalIdValid
};
