import Vue from 'vue'
import VueI18n from 'vue-i18n'

function configure(): VueI18n {

  Vue.use(VueI18n);
  const commonResourcesFR = require('./resources/common/fr.json');
  const commonResourcesDE = require('./resources/common/de.json');
  const commonResourcesNL = require('./resources/common/nl.json');

  const errorResourcesFR = require('./resources/error/fr.json');
  const errorResourcesDE = require('./resources/error/de.json');
  const errorResourcesNL = require('./resources/error/nl.json');

  const complaintResourcesFR = require('./resources/complaint/fr.json');
  const complaintResourcesDE = require('./resources/complaint/de.json');
  const complaintResourcesNL = require('./resources/complaint/nl.json');

  const eanrequestResourcesFR = require('./resources/eanRequest/fr.json');
  const eanrequestResourcesDE = require('./resources/eanRequest/de.json');
  const eanrequestResourcesNL = require('./resources/eanRequest/nl.json');

  const prcResourcesFr = require('./resources/prc/fr.json');
  const prcResourcesDe = require('./resources/prc/de.json');
  const prcResourcesNl = require('./resources/prc/nl.json');

  const protectedCustomerRequestResourcesFr = require('./resources/protected-customer-request/fr.json');
  const protectedCustomerRequestResourcesDe = require('./resources/protected-customer-request/de.json');
  const protectedCustomerRequestResourcesNl = require('./resources/protected-customer-request/nl.json');

  const consentsResourcesFr = require('./resources/consents/fr.json');
  const consentsResourcesDe = require('./resources/consents/de.json');
  const consentsResourcesNl = require('./resources/consents/nl.json');

  const regularisationResourcesFr = require('./resources/regularisation/fr.json');
  const regularisationResourcesDe = require('./resources/regularisation/de.json');
  const regularisationResourcesNl = require('./resources/regularisation/nl.json');

  return new VueI18n({
    locale: 'fr',
    messages: {
      fr: Object.assign(
        commonResourcesFR,
        complaintResourcesFR,
        eanrequestResourcesFR,
        prcResourcesFr,
        protectedCustomerRequestResourcesFr,
        errorResourcesFR,
        consentsResourcesFr,
        regularisationResourcesFr
      ),
      de: Object.assign(
        commonResourcesDE,
        complaintResourcesDE,
        eanrequestResourcesDE,
        prcResourcesDe,
        protectedCustomerRequestResourcesDe,
        errorResourcesDE,
        consentsResourcesDe,
        regularisationResourcesDe
      ),
      nl: Object.assign(
        commonResourcesNL,
        complaintResourcesNL,
        eanrequestResourcesNL,
        prcResourcesNl,
        protectedCustomerRequestResourcesNl,
        errorResourcesNL,
        consentsResourcesNl,
        regularisationResourcesNl
      )
    },
  })
}

export const i18n = configure()
