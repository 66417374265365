import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { mutations } from './mutations';
import { complaint } from './complaint';
import { apiErrors } from './api-errors';
import { eanRequest } from './ean-request';
import { protectedCustomerRequest } from './protected-customer-request';
import { regularisation } from './regularisation';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    appVersion: '0.1.0',
    lang: 'fr',
    isBack: false
  },
  modules: {
    complaint,
    apiErrors,
    eanRequest,
    protectedCustomerRequest,
    regularisation
  },
  mutations,
};

export default new Vuex.Store<RootState>(store);
