import { MeterEnergyType, RegularisationEanInformation } from '@/models'

function getInfoBoxLevel(energyType: MeterEnergyType): string {
  switch (energyType) {
    case MeterEnergyType.electricity:
      return 'primary'
    case MeterEnergyType.gas:
      return 'secondary'
    default:
      return ''
  }
}

function isRegularisationEanInfosSet(model: RegularisationEanInformation) {
  return !!model.choice
}

export const regularisationService = {
  getInfoBoxLevel,
  isRegularisationEanInfosSet
};
