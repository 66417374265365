import { SubCity } from '@/models'
import { GridAccessPoint } from '@/models/protected-customer-request'

export interface OldAddressState {
  subCity: SubCity | null,
  eanCodeElectricity: string | null,
  eanCodeGas: string | null,
  gridAccessPointElectricity: GridAccessPoint | null
  gridAccessPointGas: GridAccessPoint | null
  isSameAddress: boolean | null
}

export const InitialState: OldAddressState = {
  subCity: null,
  eanCodeElectricity: null,
  eanCodeGas: null,
  gridAccessPointElectricity: null,
  gridAccessPointGas: null,
  isSameAddress: null
}
