import { GetterTree } from 'vuex'
import { PrcRequestState } from './types';
import { RootState } from '@/store/types'

export const getters: GetterTree<PrcRequestState, RootState> = {
  httpHeaders(state: PrcRequestState): any {
    const headers: any = {
      WebRef: state.requestId
    }

    if (!!state.bearer) {
      headers.Authorization = `${state.bearer}`
    }

    return headers
  },
}
