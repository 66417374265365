import { EnergyProvider, RegularisationEanInformation } from '@/models'

export interface RegularisationMetersState {
  energyProviders: EnergyProvider[] | null
  movingDate: Date | null
  eanInfos: RegularisationEanInformation[]
  eanInfosCache: RegularisationEanInformation[]
}

export function GetInitialState(): RegularisationMetersState {
  return {
    energyProviders: null,
    movingDate: null,
    eanInfos: [],
    eanInfosCache: []
  }
}
