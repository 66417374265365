import { MutationTree } from 'vuex'
import { MeterReadingState } from './types'

export const mutations: MutationTree<MeterReadingState> = {
  STORE_MOVING_DATE(state, data: Date) {
    state.movingDate = data
  },
  CLEAR(state) {
    state.movingDate = null
  }
}
