import { MutationTree } from 'vuex';
import { CustomerState } from './types';

export const mutations: MutationTree<CustomerState> = {
  STORE(state, data: CustomerState) {
    state.isCustomer = data.isCustomer
    state.stayCustomer = data.stayCustomer
  },
  CLEAR(state) {
    state.isCustomer = null
    state.stayCustomer = null
  }
}
