import { MutationTree } from 'vuex'
import { IdentificationState, GetInitialState } from './types'
import { MeterEnergyType } from '@/models';
import { ConsumptionAddress } from '@/models/api'

export const mutations: MutationTree<IdentificationState> = {
  STORE_EAN(state, data: string | null) {
    state.ean = data
  },
  STORE_METERLASTFOURNUMBERS(state, data: string | null) {
    state.meterLastFourNumbers = data
  },
  STORE_ADDRESS(state, data: ConsumptionAddress) {
    state.address = data
  },
  STORE_ENERGYTYPE(state, data: MeterEnergyType) {
    state.energyType = data
  },
  CLEAR(state) {
    state = Object.assign(state , GetInitialState())
  },
  CLEAR_USERDATA(state) {
    state = Object.assign(state , GetInitialState(), {ean: state.ean, meterLastFourNumbers: state.meterLastFourNumbers})
  }
}
