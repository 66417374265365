import Vue from 'vue';
import { AxiosInstance } from 'axios';
import { setup } from 'axios-cache-adapter';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import { addInterceptors } from './http-interceptors';

declare module 'vue/types/vue' {

  interface Vue {
    $api: AxiosInstance;
    $inMemoryEventsHub: Vue // Used as Message Bus
  }

  interface VueConstructor {
    $api: AxiosInstance;
  }
}

export function ApiAxios(vueCtor: typeof Vue, store: Store<RootState>): void {
  // No cache by default
  const api = setup({
    baseURL: process.env.VUE_APP_API_URL,
    cache: {
      maxAge: 60 * 60 * 1000,
      ignoreCache: true,
      exclude: {
        query: false,
      },
    },
  });

  addInterceptors(api, store);

  vueCtor.prototype.$api = api;
}
