import { GetterTree } from 'vuex'
import { ContactState } from './types'
import { RootState } from '@/store/types'

export const getters: GetterTree<ContactState, RootState> = {
  hasPhoneNumber(state: ContactState): boolean {
    return !!state.contactInput
        && !!state.contactInput.mobilePhoneNumber
        && !!state.contactInput.mobilePhoneNumber.length
  },
  sendByMail(state: ContactState): boolean {
    return state.contactInput?.sendByMail ?? false
  },
  mobilePhoneNumber(state: ContactState): string | null {
    return state.contactInput?.mobilePhoneNumber ?? null
  },
  firstName(state: ContactState): string | null {
    return state.contactInput?.firstName ?? null
  },
  lastName(state: ContactState): string | null {
    return state.contactInput?.lastName ?? null
  }
}
