import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import {  ApiErrorsState } from './types';
import { RootState } from '@/store/types';

export const initialState: ApiErrorsState = {
    apiErrors: null,
};

const namespaced: boolean = true;

export const apiErrors: Module<ApiErrorsState, RootState> = {
    namespaced,
    state: initialState,
    getters,
    actions,
    mutations,
};
