export interface ComplaintInfo {
  fileNumber: string | null;
  description: string | null;
  bankAccountBic: string | null;
  bankAccountIban: string | null;
  damageType: DamageType | null;
  sinisterDate: Date | null;
  email: string | null;
  isIndemnitySendToLegal: boolean
}

export enum DamageType {
  corporal = 'corporal',
  material = 'material'
}
