import { GetterTree } from 'vuex'
import { AddressState } from './types'
import { RootState } from '@/store/types'
import { EanRequestAddress } from '../../../models/ean-request'

export const getters: GetterTree<AddressState, RootState> = {
  formattedAddress(state: AddressState): string {
    if (!state.subCity || !state.streetSubCity || !state.houseNumberAsset) {
      return ''
    }
    const nmbr = state.houseNumberAsset?.houseNumber
    const box = state.houseNumberAsset?.houseBox
    const boxStr = box?.complement ?? box?.complementBox ?? box?.floorNumber ?? ''
    return `${state.streetSubCity?.name} ${nmbr}${boxStr} - ${state.subCity?.zipCode} ${state.subCity?.cityName}`
  },
  eanRequestAddress(state: AddressState): EanRequestAddress {
    if (!state.subCity || !state.streetSubCity || !state.houseNumberAsset || !state.meterSerialNumberLastFourDigits) {
      throw TypeError('addressState not set')
    }
    return {
      subCity: state.subCity,
      streetSubCity: state.streetSubCity,
      houseNumberAsset: state.houseNumberAsset,
      meterSerialNumberLastFourDigits: state.meterSerialNumberLastFourDigits
    }
  },
  meterLastDigits(state: AddressState): string | null {
    return state.meterSerialNumberLastFourDigits
  }
}
