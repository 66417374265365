import { AxiosInstance, AxiosError } from 'axios';
import { Store } from 'vuex';
import { RootState } from '@/store/types';

export function addInterceptors(axios: AxiosInstance, store: Store<RootState>): void {
  axios.interceptors.response.use((response) => response, (error: AxiosError) => {
    store.dispatch('apiErrors/manage', error.response);
    return Promise.reject(error);
  });

  axios.interceptors.request.use((request) => {
    request.headers['Accept-Language'] = store.state.lang;
    return request;
  });
}
