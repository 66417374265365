import Vue, { VueConstructor } from 'vue';
import { VueAppInsights } from './application-insights';
import { AddressRepositoryAxios } from './address-repository';
import { ApiAxios } from './api-client';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { ImageProvider } from './image-provider';
import { ValidatorObserver } from './validator-observer';

interface Settings {
  addressApiUrl: string;
  apiUrl: string;
  appInsightsKey: string;
  mainSiteUrl: string;
}

declare module 'vue/types/vue' {
  interface Vue {
    $settings: Settings;
    $testMode: boolean;
  }

  interface VueConstructor {
    $settings: Settings;
    $testMode: boolean;
  }
}

export function InstallApp(vueCtor: VueConstructor<Vue>, options: { store: Store<RootState> }) {
  const settings: Settings = {
    addressApiUrl: process.env.VUE_APP_ADDRESS_API_URL!,
    apiUrl: process.env.VUE_APP_API_URL!,
    appInsightsKey: process.env.VUE_APP_INSIGHTS_KEY!,
    mainSiteUrl: process.env.VUE_APP_MAIN_SITE_URL!,
  };

  vueCtor.prototype.$settings = settings;
  vueCtor.$settings = settings;
  const testMode = process.env.VUE_APP_TEST_MODE === 'YES';
  vueCtor.prototype.$testMode = testMode;
  vueCtor.$testMode = testMode;

  vueCtor.use(AddressRepositoryAxios, options.store);
  vueCtor.use(ApiAxios, options.store);
  vueCtor.use(VueAppInsights, {
    id: vueCtor.$settings.appInsightsKey,
  });
  vueCtor.use(ImageProvider);
  vueCtor.use(ValidatorObserver);

  // Message bus for in-memory events
  Vue.prototype.$inMemoryEventsHub = new Vue();
}
