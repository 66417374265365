import { MutationTree } from 'vuex';
import { ComplaintTypeState } from './types';

export const mutations: MutationTree<ComplaintTypeState> = {
  STORE(state, data: string) {
    state.complaintType = data;
  },
  CLEAR(state) {
    state.complaintType = null;
  },
};
