










import { Vue, Component } from 'vue-property-decorator';
import { routeAlternate } from './router';
import { ChatConfiguration } from '@ores/vue-library'

@Component
export default class App extends Vue {
  //#region [Computed]
  public get isTest(): boolean {
    return !/[.]ores[.]be$/.test(location.hostname)
  }

  public get chatConfig(): ChatConfiguration {
    return {
      deploymentId: process.env.VUE_APP_CHAT_DEPLOYMENT_ID,
      isDebug: process.env.VUE_APP_CHAT_DEBUG === '1'
    }
  }
  //#endregion

  //#region [Methods]
  public mounted() {
    this.setTagManager()
  }

  public onSwitchLang(lang: [string, string]) {
    const currentLang = lang[0];
    const selectedLang = lang[1];

    routeAlternate(currentLang, selectedLang);
  }

  public setTagManager(): void {
    const gtmId = 'GTM-PLCN96'
    const gtmDataLayer = 'dataLayer'

    window[gtmDataLayer] = window[gtmDataLayer] || []
    const gtm = window[gtmDataLayer]
    gtm.push({
      'gtm.start': new Date().getTime(),
      'event': 'gtm.js'
    })
    const parentScriptTag = document.getElementsByTagName('script')[0]
    const gtmScriptTag = document.createElement('script')
    const dl = gtmDataLayer !== 'dataLayer' ? '&l=' + gtmDataLayer : ''

    gtmScriptTag.async = true
    gtmScriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dl

    parentScriptTag?.parentNode?.insertBefore(gtmScriptTag, parentScriptTag)
  }
  //#endregion
}
