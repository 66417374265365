import { Module } from 'vuex'
import { mutations } from './mutations'
import { GetInitialState, OwnerState } from './types'
import { RootState } from '@/store/types'
import { getters } from './getters';

export const owner: Module<OwnerState, RootState> = {
  namespaced: true,
  state: GetInitialState(),
  mutations,
  getters
}
