export default {
  name: 'complaint-number-file',
  validate: (value: string | null) => {
    const valueDefined = !!value ? value.toString().trim() : false;
    if (!valueDefined) {
      return true;
    }
    const strVal = String(value);
    // Test numéro dossier plainte et numéro dossier indemnisation
    return /^4[0-9]{7}$/.test(strVal) || /^3[0-9]{5}$/.test(strVal) || /^((BCA)|(RC)){0,1}3[0-9]{5}$/.test(strVal)
  },
};
