import { GetterTree } from 'vuex'
import { IdentificationState } from './types';
import { RootState } from '@/store/types'
import { contactService } from '@/services';

export const getters: GetterTree<IdentificationState, RootState> = {
  formattedAddress(state: IdentificationState): string {
    return contactService.getFormattedConsumptionAddress(state.address)
  },
  isAddressFound(state: IdentificationState): boolean {
    return !!state.address
  },
}
