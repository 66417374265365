import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { EanRequestState } from './types'

export const actions: ActionTree<EanRequestState, RootState> = {
  reset({ commit }) {
    commit('address/CLEAR')
    commit('contact/CLEAR')
    commit('CLEAR')
  }
}
