import { ActionTree } from 'vuex'
import { IdentificationState } from './types';
import { RootState } from '@/store/types';
import { CategorySelectionStepModel } from '@/models';
import { complaintService } from '@/services';

export const actions: ActionTree<IdentificationState, RootState> = {
  validate({ commit, state }, category: CategorySelectionStepModel) {
    const eanRequiredCat = complaintService.isInList(category, complaintService.eanRequiredList)
    if (eanRequiredCat && state.identification?.identificationType === 'address') {
      return false
    }

    const oresAddressOnlyCat = complaintService.isInList(category, complaintService.oresAddresOnlyList)

    if (state.identification?.identificationType === 'address' && state.identification.address !== null) {
      if (oresAddressOnlyCat && state.identification.address.subCity) {
        const subCity = state.identification.address.subCity
        const elecGrd =
          (subCity.operationalOffices.electricityOffice &&
            subCity.gridManagers.electricityManager &&
            subCity.gridManagers.electricityManager.isOres) ??
          false
        const gasGrd =
          (subCity.operationalOffices.gasOffice &&
            subCity.gridManagers.gasManager &&
            subCity.gridManagers.gasManager.isOres) ??
          false
        if (!elecGrd && !gasGrd) {
          return false;
        }
      }
      if (state.identification.address.streetSubCity === null &&
        !complaintService.isInList(category, complaintService.streetNotRequiredList)) {
        return false
      }
      if (state.identification.address.houseNumber === null &&
        !complaintService.isInList(category, complaintService.numberNotRequiredList)) {
        return false
      }
    }

    return true
  }
}
