import { GetterTree } from 'vuex';
import { EnergyState } from './types';
import { RootState } from '@/store/types';

export interface Step2Getters {
  isElectricitySelected: boolean;
  isGasSelected: boolean;
}

export const getters: GetterTree<EnergyState, RootState> = {
  isElectricitySelected(state): boolean {
    return !!state.energySelectionStepInfo &&
    !!state.energySelectionStepInfo.energySelection &&
    state.energySelectionStepInfo.energySelection.electricity;
  },
  isGasSelected(state): boolean {
    return !!state.energySelectionStepInfo &&
    !!state.energySelectionStepInfo.energySelection &&
    state.energySelectionStepInfo.energySelection.gas;
  },
};
