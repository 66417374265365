export interface ContactState {
  isEanFound: boolean,
  contactInput: ContactInputState | null,
  isRetry: boolean
}

export interface ContactInputState {
  sendByMail: boolean,
  mobilePhoneNumber: string | null,
  firstName: string | null,
  lastName: string | null
}

export function GetInitialState(): ContactState {
  return {
    isEanFound: false,
    contactInput: null,
    isRetry: false
  }
}
