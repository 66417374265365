import { MutationTree } from 'vuex'
import { OldAddressMeterReadingState } from './types'

export const mutations: MutationTree<OldAddressMeterReadingState> = {
  STORE_MOVING_DATE(state, data: Date) {
    state.movingDate = data
  },
  CLEAR(state) {
    state.movingDate = null
  }
}
