import { idGenerator } from '@/services';

export interface PrcRequestState {
  requestId: string
  bearer: string | null
}

export function GetInitialState(): PrcRequestState {
  return {
    requestId: idGenerator.getNewWebReference(),
    bearer: null
  }
}
