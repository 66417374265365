import { idGenerator } from '@/services'

export interface EanRequestState {
  requestId: string
  captchaToken: string | null
  bearer: string | null
}

export function GetInitialState(): EanRequestState {
  return {
    requestId: idGenerator.getNewWebReference(),
    captchaToken: null,
    bearer: null
  }
}
