import { GetterTree } from 'vuex'
import { RegularisationMetersState } from './types';
import { RootState } from '@/store/types'
import { regularisationService } from '@/services'

export const getters: GetterTree<RegularisationMetersState, RootState> = {
  hasEanInfos(state: RegularisationMetersState): boolean {
    return !!state.eanInfos
      && !!state.eanInfos.filter((o) => regularisationService.isRegularisationEanInfosSet(o)).length
  }
}
