import { ActionTree } from 'vuex';
import { ContactInfoState } from './types';
import { RootState } from '@/store/types';
import { IdentificationModel } from '@/models';

export const actions: ActionTree<ContactInfoState, RootState> = {
  validate({ commit, state }, identification: IdentificationModel): boolean {
    return !(identification.address !== null &&
      (identification.address.streetSubCity === null || identification.address.houseNumber === null) &&
      (state.contactInfo !== null && state.contactInfo.useOtherAddress === false))
  },
};
