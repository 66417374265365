export interface RegularisationState {
  requestId: string
  bearer: string | null
}

export function GetInitialState(): RegularisationState {
  return {
    requestId: '',
    bearer: null
  }
}
