import Vue, { ComponentOptions, AsyncComponent } from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Maintenance from './views/Maintenance.vue'
import { NavigationGuard } from 'vue-router/types/router';

Vue.use(Router);

interface I18nRoute {
  name: string;
  paths: { [key: string]: string } & { fr: string, nl: string, de: string };
  component: ComponentOptions<Vue> | typeof Vue | AsyncComponent;
  beforeEnter?: NavigationGuard
}

const blockInProduction: NavigationGuard = (to, from, next) => {
  // Only allow when NOT in production
  if (process.env.VUE_APP_ENV !== 'production') {
    next()
  } else {
    window.location.href = 'https://www.ores.be/';
  }
}

export const routes: I18nRoute[] = [
  {
    name: 'home',
    paths: {
      fr: '/',
      nl: '/nl',
      de: '/de',
    },
    component: Home,
    beforeEnter: blockInProduction
  },
  {
    name: 'complaint',
    paths: {
      fr: '/plainte',
      nl: '/klacht',
      de: '/beschwerde',
    },
    component: async () => import('./views/forms/Complaint.vue')
  },
  {
    name: 'prc',
    paths: {
      fr: '/prc',
      nl: '/cba',
      de: '/kgk',
    },
    component: async () => import('./views/forms/PRC.vue'),
  },
  {
    name: 'protected-customer-request',
    paths: {
      fr: '/client-protege',
      nl: '/beschermde-klant',
      de: '/geschutzter-kunde',
    },
    component: async () => import('./views/forms/ProtectedCustomerRequest.vue')
  },
  {
    name: 'consents',
    paths: {
      fr: '/consents',
      nl: '/consents',
      de: '/consents',
    },
    component: async () => import('./views/forms/Consents.vue'),
    beforeEnter: blockInProduction
  },
  {
    name: 'eanrequest',
    paths: {
      fr: '/code-ean',
      nl: '/ean-code',
      de: '/ean-kode',
    },
    component: async () => import('./views/forms/EanRequest.vue')
  },
  {
    name: 'regularisation',
    paths: {
      fr: '/regularisation',
      nl: '/regularisatie',
      de: '/regulierung',
    },
    component: async () => import('./views/forms/Regularisation.vue')
  },
  {
    name: 'maintenance',
    paths: {
      fr: '/maintenance',
      nl: '/onderhoud',
      de: '/wartung'
    },
    component: Maintenance
  },
];

const router = new Router({
  mode: 'history',
  routes: [
    ...routes.map((r) => {
      return Object.entries(r.paths).map((p) => {
        return {
          name: `${r.name}-${p[0]}`,
          path: p[1],
          component: r.component,
          props: { lang: p[0] },
          beforeEnter: r.beforeEnter,
        };
      });
    }).flat(),
  ],
});

export function routeAlternate(currentLang: string, targetLang: string) {
  const currentRoute = router.currentRoute.path;
  const route = routes.find((r) => r.paths[currentLang] === currentRoute);
  if (route != null) {
    router.push({ path: route.paths[targetLang] });
  }
}

export default router;
