import { MutationTree } from 'vuex';
import { EnergyState } from './types';
import { EnergySelectionStepModel } from '@/models';

export const mutations: MutationTree<EnergyState> = {
  STORE(state, data: EnergySelectionStepModel) {
    state.energySelectionStepInfo = data;
  },
  CLEAR(state) {
    state.energySelectionStepInfo = null;
  },
};
