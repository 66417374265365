import { Module } from 'vuex'
import { GetInitialState, PrcRequestState } from './types'
import { RootState } from '@/store/types'
import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { customer } from './customer'
import { energy } from './energy'
import { prosumer } from './prosumer'
import { identification } from './identification'
import { meterReading} from './meter-reading'
import { oldAddressEnergy } from './old-address-energy'
import { oldAddress } from './old-address'
import { oldAddressMeterReading} from './old-address-meter-reading'
import { attestation } from './attestation'
import { contactInfo } from './contact-info'
import { owner } from './owner'

export const protectedCustomerRequest: Module<PrcRequestState, RootState> = {
  namespaced: true,
  state: GetInitialState(),
  mutations,
  actions,
  getters,
  modules: {
    customer,
    energy,
    prosumer,
    identification,
    meterReading,
    oldAddressEnergy,
    oldAddress,
    oldAddressMeterReading,
    attestation,
    contactInfo,
    owner
  },
}
