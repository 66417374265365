import { OwnerInfo } from '@/models';
import { contactService } from '@/services';

export interface OwnerState {
  isSameAsClientInfo: boolean | null,
  isOwnerInfoKnown: boolean | null,
  ownerInfos: OwnerInfo
}

export function GetInitialState(): OwnerState {
  return {
    isSameAsClientInfo: null,
    isOwnerInfoKnown: null,
    ownerInfos: {
      contactBaseInfo: contactService.createContactBase(),
      address: contactService.createContactAddress()
    }
  }
}
