import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { RegularisationState } from './types'
import { AuthenticateResponse } from '@/models'

export const actions: ActionTree<RegularisationState, RootState> = {
  STORE_IDENTIFICATION({ commit }, data: AuthenticateResponse): void {
    commit('identification/STORE_ADDRESS', data.address)
    commit('identification/STORE_ENERGYTYPE', data.energyType)
    commit('STORE_BEARER', data.bearer)
    commit('STORE_WEBREF', data.webReference)
  },
  CLEAR_STEPS({commit}, currentStep: number): void {
    const stepToInvalidateMap = new Map<number, number[]>([
      [1, [1, 2, 3, 4, 5]],
      [2, [3]],
      [3, []],
      [4, []],
      [5, []],
    ])

    const stepTypeMap = new Map<number, string[]>([
      [1, ['CLEAR_BEARER', 'identification/CLEAR_USERDATA']],
      [2, ['contact/CLEAR']],
      [3, ['contact/CLEAR_CONTACT']],
      [4, ['owner/CLEAR']],
      [5, ['regularisationMeters/CLEAR_USERDATA']]
    ])

    stepToInvalidateMap.get(currentStep)?.forEach(
      (step) => stepTypeMap.get(step)?.forEach(
        (type) => commit(type)))
  },
  RESET({commit}): void {
    commit('regularisationMeters/CLEAR')
    commit('owner/CLEAR')
    commit('contact/CLEAR')
    commit('identification/CLEAR')
    commit('CLEAR')
  }
}
