import { MutationTree } from 'vuex'
import { AttestationState } from './types'
import { FileInfo } from '@/models'

export const mutations: MutationTree<AttestationState> = {
  STORE(state, data: FileInfo[]) {
    state.attachDocuments = !!data.length
    state.attachments = data
  },
  CLEAR(state) {
    state.attachDocuments = false
    state.attachments = []
  },
}
