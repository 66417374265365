import { RegularisationContactInfos } from '@/models';
import { contactService } from '@/services';

export interface ContactState {
  isPro: boolean | null
  contactInfos: RegularisationContactInfos | null
}

export function GetInitialState(): ContactState {
  return {
    isPro: null,
    contactInfos: {
      contactBaseInfo: contactService.createContactBase(),
      formerAddress: contactService.createContactAddress(),
      billingAddress: contactService.createContactAddress(),
      isSameBillingAddress: true,
    }
  }
}
