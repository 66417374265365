import { ContactBaseInfo, ContactAddress, MeterEnergyType } from '@/models'
import { ConsumptionAddress, EanInformation } from './api'

export enum RegularisationChoiceType {
  KeepMyContract = 1,
  FormerResidentContract = 2,
  EmptyHome = 3,
  CloseMeter = 4
}

export interface AuthenticateResponse {
  ean: string
  energyType: MeterEnergyType
  address: ConsumptionAddress
  bearer: string
  webReference: string
}

export interface RegularisationContactInfos {
  contactBaseInfo: ContactBaseInfo | null
  formerAddress: ContactAddress | null
  billingAddress: ContactAddress | null
  isSameBillingAddress: boolean
}

export interface RegularisationChoiceData {
  choiceType: RegularisationChoiceType
  energyProvider: EnergyProvider | null
  contractReference: string | null
}

export interface RegularisationEanInformation extends EanInformation {
  choice: RegularisationChoiceData | null
}

export interface EnergyProvider {
  name: string,
  code: string,
  energyType: string
}
