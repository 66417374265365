import { GetterTree } from 'vuex';
import { CategorySelectionState } from './types';
import { RootState } from '@/store/types';
import { complaintService } from '@/services';

export interface CategorySelectionStepGetterTree {
  isIndemnityRequest: boolean;
}

export const getters: GetterTree<CategorySelectionState, RootState> = {
  isIndemnityRequest(
    state: CategorySelectionState,
    gets: CategorySelectionStepGetterTree,
    rs: RootState,
    rg: any
  ): boolean {
    return complaintService.isInList(state.category, complaintService.indemnityRequestList)
  },
  isIndemnitySendToLegal(state: CategorySelectionState): boolean {
    return complaintService.isInList(state.category, complaintService.indemnitySendToLegal)
  },
};
