import { GetterTree } from 'vuex'
import { EanRequestState } from './types'
import { RootState } from '@/store/types'

export const getters: GetterTree<EanRequestState, RootState> = {
  httpHeaders(state: EanRequestState): any {
    const headers: any = {
      WebRef: state.requestId
    }

    if (!!state.captchaToken) {
      headers['g-recaptcha-response'] = state.captchaToken
    }

    if (!!state.bearer) {
      headers.Authorization = `Bearer ${state.bearer}`
    }

    return { headers }
  }
}
