import { Module } from 'vuex'
import { getters } from './getters'
import { mutations } from './mutations'
import { IdentificationState, GetInitialState } from './types'
import { RootState } from '@/store/types'

export const identification: Module<IdentificationState, RootState> = {
  namespaced: true,
  state: GetInitialState(),
  getters,
  mutations
}
