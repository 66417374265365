import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { InitialState, ComplaintState } from './types';
import { contactInfo } from './contact-info';
import { complaintInfo } from './complaint-info';
import { complaintType } from './complaint-type';
import { identification } from './identification';
import { newOrTracking } from './new-or-tracking';
import { energy } from './energy';
import { categorySelection } from './category-selection';
import { attachments } from './attachments';
import { RootState } from '@/store/types';

const namespaced: boolean = true;

export const complaint: Module<ComplaintState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations,
  modules: {
    identification,
    complaintInfo,
    complaintType,
    newOrTracking,
    contactInfo,
    energy,
    categorySelection,
    attachments,
  },
};
