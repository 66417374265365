import { contactService } from '@/services';

export default {
  name: 'national-id',
  validate: (value: string | null) => {
    const valueDefined = !!value ? value.toString().trim() : false;
    if (!valueDefined) {
      return true;
    }
    return contactService.isNationalIdValid(value ?? '')
  },
};
