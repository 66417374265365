import { MutationTree } from 'vuex'
import { EanRequestState, GetInitialState } from './types'

export const mutations: MutationTree<EanRequestState> = {
  STORE_CAPTCHA_TOKEN(state, data: string) {
    state.captchaToken = data
  },
  STORE_BEARER(state, data: string) {
    state.bearer = data
    state.captchaToken = null
  },
  CLEAR_BEARER(state) {
    state.bearer = null
  }
}
