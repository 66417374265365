import { Module } from 'vuex'
import { GetInitialState, RegularisationState } from './types'
import { RootState } from '@/store/types'
import { mutations } from './mutations'
import { getters } from './getters'
import { actions } from './actions'
import { identification } from './identification'
import { contact } from './contact'
import { owner } from './owner'
import { regularisationMeters } from './regularisationMeters'

export const regularisation: Module<RegularisationState, RootState> = {
  namespaced: true,
  state: GetInitialState(),
  mutations,
  actions,
  getters,
  modules: {
    identification,
    contact,
    owner,
    regularisationMeters
  }
}
